import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: (resolve) => void require(["../views/Index.vue"], resolve),
    meta: {
      title: "Finnet",
      requireAuth: true,
    },
  },
	{
	  path: "/year",
	  name: "year",
	  component: (resolve) => void require(["../views/year.vue"], resolve),
	  meta: {
	    title: "Finnet",
	    requireAuth: true,
	  },
	},
  {
    path: "/login",
    name: "Login",
    component: (resolve) => void require(["../views/Login.vue"], resolve),
    meta: {
      title: "授权登录",
    },
  },

];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
