import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store";
import "lib-flexible"; // 引入适配包
import "./assets/fonts/fonts.css"; //引入外部字体
import "./assets/icon/iconfont.css";
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

// router.beforeEach((to, from, next) => {
//   var token = localStorage.getItem('code');
//   if (to.meta.requireAuth) {
//       if (token == 'undefined' || token == null || token == '') {
//           next({ path: '/login'})
//       } else {
//           next()
//           if (to.meta.title) {
//               document.title = ' Finnet- ' + to.meta.title
//           } else {
//               document.title = 'Finnet'
//           }
//       }
//   } else {
//       next();
//       if (to.meta.title) {
//           document.title = 'Finnet - ' + to.meta.title
//       } else {
//           document.title = 'Finnet'
//       }
//   }
// })
// 全局封装接口调用
import util from "./utils/util.js";
Vue.prototype.$util = util;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
